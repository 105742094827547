// Entry point for the build script in your package.json

import "./add_jquery"
import "jquery-ujs"
import "jquery-ui"
import "core-js/stable"
import "regenerator-runtime/runtime"
import "./src/scripts"
import "./src/geoxml3"
import "@eastdesire/jscolor"

jscolor.presets.default = {
    format:'any', previewPosition:'right', previewSize:60, 
    borderColor:'rgba(156,156,156,1)', borderRadius:0, padding:6, 
    closeText:'Close Picker', controlBorderColor:'rgba(138,138,138,1)', 
    controlBorderWidth:0, sliderSize:12, crossSize:7, 
    pointerThickness:1, pointerBorderWidth:2, shadow:false, 
    shadowColor:'rgba(255,18,22,0.2)'
};
