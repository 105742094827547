$(function(){

  setTimeout(function(){
    $("#flash_notice, #flash_error").fadeTo(4000, 0).animate({ height: 0, padding: 0, margin: 0 }, 500)
  }, 5000);

  $("#table").on("click", "h2", function(e){
    $(this).siblings("div").slideToggle(500, function(){
      if ($(this).css("display") == "block") {
        $("#table, #table div").css("width", "400px");
      } else {
        $("#table, #table div").css("width", "199px");
      }
    });
  });

  $(".closer").on("click", function(){
    $(this).parent().fadeOut()
  });

});


window.map;
window.geoXml;
window.beef = {
  variables: {
    markers: {},
    kmls: {},
    lines: {},
    info: new google.maps.InfoWindow({content: ""}),
    polyline: new google.maps.Polyline({
      strokeColor: 		"#ff0000",
      strokeOpacity: 	0.9,
      strokeWeight: 	3,
      map: 				window.map 
    }),
    polylinePath: Object,
	visibleComments: true
  },

  defaults: {
    zoom:           5,
    lat:            45,
    lng:            -90,
    type:           google.maps.MapTypeId.ROADMAP,
    scrollwheel: 	true,
    doubleclick: 	false,
    id:             "map"
  },

  load: function(){
    map = new google.maps.Map(document.getElementById(this.defaults.id), {
      zoom: 								this.defaults.zoom,
      center: 								new google.maps.LatLng(this.defaults.lat, this.defaults.lng),
      tilt:                                 0,
      mapTypeId: 							this.defaults.type,
      disableDoubleClickZoom:               !this.defaults.doubleclick,
      scrollwheel:      				    this.defaults.scrollwheel,
      mapTypeControlOptions: {
        mapTypeIds: [
          google.maps.MapTypeId.TERRAIN,
          google.maps.MapTypeId.SATELLITE,
          google.maps.MapTypeId.HYBRID,
          'topography',
          google.maps.MapTypeId.ROADMAP
        ]
      }
    });

    geoXml = new geoXML3.parser({
      map: map,
      singleInfoWindow: true
    });
  },

  infoWindow: {
    open: function(id, info){
      beef.variables.info.setContent(info);
      beef.variables.info.open(map, beef.variables.markers[id]);
    },
    listener: function(id, info){
      google.maps.event.addListener(beef.variables.markers[id], "click", function(){
        beef.infoWindow.open(id, info);
      })
    } 
  },

  markers: {
    populate: function(markers){
      jQuery.each(markers, function(key, marker){
        beef.variables.markers[marker.id] = new google.maps.Marker({
          position: 		new google.maps.LatLng(marker.lat, marker.lng),
          map:              map,
          cursor: 			"pointer",
          icon:             marker.icon
        });
        beef.infoWindow.listener(marker.id, marker.info)
      })
    }
  },

  polylines: {
    populate: function(lines){
      let lineSymbol = {
        path: 'M 0,-.7 0,.7',
        strokeOpacity: 0.8,
        scale: 5
      };
      jQuery.each(lines, function(key, line){
        var latlngs = jQuery.map(line.path, function(point, key){
          return new google.maps.LatLng(parseFloat(point[0]), parseFloat(point[1]))
        });

        if (line.dashed) {
          beef.variables.lines[line.id] = new google.maps.Polyline({
            strokeColor: 		line.color,
            strokeOpacity: 	0,
            icons: [{
              icon: lineSymbol,
              offset: '0',
              repeat: '20px'
            }],
            path: 			latlngs
          });
        }
        else {
          beef.variables.lines[line.id] = new google.maps.Polyline({
            strokeColor: 		line.color,
            strokeOpacity: 	0.8,
            strokeWeight: 	5,
            path: 			latlngs
          });
        }
        beef.variables.lines[line.id].setMap(map);

        google.maps.event.addListener(beef.variables.lines[line.id], "click", function(e){
          beef.variables.info.setContent(line.info);
          beef.variables.info.setPosition(e.latLng);
          beef.variables.info.open(map);
        })

        google.maps.event.addListener(beef.variables.lines[line.id], "mouseover", function(e){
          beef.variables.lines[line.id].setOptions({strokeWeight: 8});
        })

        google.maps.event.addListener(beef.variables.lines[line.id], "mouseout", function(e){
          beef.variables.lines[line.id].setOptions({strokeWeight: 5});
        })

      })
    },

    startDrawing: function(){
      beef.variables.polylinePath = beef.variables.polyline.getPath();
      beef.variables.polyline.setMap(map);
      google.maps.event.addListener(map, "click", this.addLatLng);

    },
    addLatLng: function(event){
      beef.variables.polylinePath.push(event.latLng);
      if (beef.variables.polylinePath.getLength() == 1) {
        beef.variables.markers["new"] = new google.maps.Marker({
          map:          map,
          position: 	beef.variables.polylinePath.getAt(0)
        });
        beef.infoWindow.listener("new", "<div class='added'><strong>Great!</strong> <span id='lineToMarker'>Comment on this point by clicking <a href='javascript:beef.lineToMarker();'>here</a><br /> OR continue clicking on the map to draw a line.</span> <br /> <br />Mess up? No problem, <a href='javascript:beef.undo()'>just reset the map.</a></em></div>");
        beef.infoWindow.open("new", "<div class='added'><strong>Great!</strong> <span id='lineToMarker'>Comment on this point by clicking <a href='javascript:beef.lineToMarker();'>here</a><br /> OR continue clicking on the map to draw a line.</span> <br /><br />Mess up? No problem, <a href='javascript:beef.undo()'>just reset the map.</a></em></div>");
      }

      if (beef.variables.polylinePath.getLength() > 1) {
        var $line = $("#comment_line");
        $line.val("");
        beef.variables.polylinePath.forEach(function(element, num){
          $line.val($line.val() + element.lat() + "," + element.lng() + "|");
          if (num == beef.variables.polylinePath.getLength()) {
            $line.val($line.val().substr(0, $line.val().length-2));
          }
        });
        $("#comments").fadeIn();
        setTimeout(function(){$("#comment_body").focus();}, 500);
      }
    }
  },

  kmls: {
    populate: function(kmls){
      jQuery.each(kmls, function(key, kml){
        // convert any dashed linework from KML to google maps polyline so dashed style can be applied
        if (kml.dashed) {
          try {
            geoXml.parseKmlString(kml.data.replace(/(\r\n|\n|\r)/gm," "));
            let index = geoXml.docs.length-1
            let lineSymbol = {
              path: 'M 0,-1 0,1',
              strokeOpacity: 1,
              scale: 4
            };
            for (var i = 0; i < geoXml.docs[index].gpolylines.length; i++) {
              geoXml.docs[index].gpolylines[i].setOptions({
                strokeOpacity: 0,
                icons: [{
                  icon: lineSymbol,
                  offset: '0',
                  repeat: '20px'
                }],
                suppressInfoWindows: true,
                clickable: kml.clickable
              });
            }
            for (var i = 0; i < geoXml.docs[index].gpolygons.length; i++) {
              let path = geoXml.docs[index].gpolygons[i].getPath()
              let color = geoXml.docs[index].gpolygons[i].strokeColor;
              geoXml.docs[index].gpolygons[i].setOptions({
                strokeOpacity: 0,
                suppressInfoWindows: true,
                clickable:           kml.clickable
              });
              let pl = new google.maps.Polyline({
                strokeColor: color,
                strokeOpacity: 0,
                icons: [{
                  icon: lineSymbol,
                  offset: '0',
                  repeat: '20px'
                }],
                path: path,
                map: map
              });
              geoXml.docs[index].gpolylines.push(pl);
            }
            beef.variables.kmls[kml.id] = geoXml.docs[index];
          }
          catch (e) {
            console.log('GeoXML3 Parse Error.  Problem with the KML id '+kml.id+' '+e)
          }
        }
        // add the non-dashed linework as a KML layer
        else {
          beef.variables.kmls[kml.id] = new google.maps.KmlLayer(
            kml.url,
            {
              map: 								 map,
              preserveViewport: 	 true,
              suppressInfoWindows: true,
              clickable:           kml.clickable
            });
        }
      });
    }
  },

  undo: function(){
    //resets the markers for point placement
    this.variables.markers["new"].setMap(null);
    this.variables.polyline.setPath(new google.maps.MVCArray());
    this.variables.info.close();
    this.markerOnMap = false;
    google.maps.event.clearListeners(map, "click");
    $("#comments").fadeOut();
    $("#map_lat, #map_lng, #map_zoom, #map_study_area, #comment_lat, #comment_lng, #comment_line, #comment_note").val("");
  },

  markerListeners: function(){
    that = this;
    google.maps.event.addListener(map, "dblclick", function(e){
      that.placeMarker(e);
    });
  }, 

  lineListeners: function(){
    google.maps.event.addListener(map, "dblclick", function(e){
      beef.polylines.startDrawing();
      beef.polylines.addLatLng(e);
    });
  },

  zoomListener: function(zoomNumber){
	  google.maps.event.addListener(map, 'zoom_changed', function(e) {
		zoomLevel=map.getZoom();
		if (zoomNumber >= zoomLevel && beef.variables.visibleComments) {
			Object.keys(beef.variables.markers).forEach(function (key) {
				beef.variables.markers[key].setVisible(false)
			});
			beef.variables.visibleComments = false
		} else if (zoomNumber < zoomLevel && !beef.variables.visibleComments) {
			Object.keys(beef.variables.markers).forEach(function (key) {
				beef.variables.markers[key].setVisible(true)
			});
			beef.variables.visibleComments = true
		}
	  });
  },

  searchBox: function(){
	  var markers = []
	  var input = document.getElementById('pac-input');
	  map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

	  var searchBox = new google.maps.places.SearchBox(input);

	  // Listen for the event fired when the user selects an item from the
	  // pick list. Retrieve the matching places for that item.
	  google.maps.event.addListener(searchBox, 'places_changed', function() {
	    var places = searchBox.getPlaces();

	    if (places.length == 0) {
	      return;
	    }
	    for (var i = 0, marker; marker = markers[i]; i++) {
	      marker.setMap(null);
	    }

	    // For each place, get the icon, place name, and location.
	    markers = [];
	    var bounds = new google.maps.LatLngBounds();
	    for (var i = 0, place; place = places[i]; i++) {
	      var image = {
	        url: place.icon,
	        size: new google.maps.Size(71, 71),
	        origin: new google.maps.Point(0, 0),
	        anchor: new google.maps.Point(17, 34),
	        scaledSize: new google.maps.Size(25, 25)
	      };

	      // Create a marker for each place.
	      var marker = new google.maps.Marker({
	        map: map,
	        icon: image,
	        title: place.name,
	        position: place.geometry.location
	      });

	      markers.push(marker);

	      bounds.extend(place.geometry.location);
	    }

	    map.fitBounds(bounds);
	  });

	  // Bias the SearchBox results towards places that are within the bounds of the
	  // current map's viewport.
	  google.maps.event.addListener(map, 'bounds_changed', function() {
	    var bounds = map.getBounds();
	    searchBox.setBounds(bounds);
	  });
  },

  commentReply: function(e){
    // Set up the form for a comment reply
    $("#comment_lat").val(e.latLng.lat());
    $("#comment_lng").val(e.latLng.lng());
    $("#comment_comment_id").val(e.comment);
    $("#comment_heading").text("Comment reply");
    $("#comment_comment_option_id_input, #comment_questions").hide();
    $("#comments").fadeIn();
    document.querySelector('#comments').scrollIntoView({
      behavior: 'smooth' 
    });
  },

  setupForm: function(e) {
    // Setup the form for a full comment in case it was a reply
    $("#comment_comment_id").val(null);
    $("#comment_heading").text("Comments box")
    $("#comment_comment_option_id_input, #comment_questions").show();
    if (e.commentNote) {
      $("#comment_note").val(e.commentNote);
      $("#comment_note_input").removeClass("hidden");
    }
  },

  placeMarker: function(e){
    if (!this.markerOnMap) {
      //Form insertion
      $("#comment_lat").val(e.latLng.lat());
      $("#comment_lng").val(e.latLng.lng());
      this.setupForm(e);

      beef.variables.markers["new"] = new google.maps.Marker({
        map: 				map,
        position: 	e.latLng
      });

      beef.infoWindow.listener("new", "<div class='added'><strong>Great!</strong> Please continue filling out the form to the left.<br /><br /><em>(Didn't mean to put this marker on the map? No problem, <a href='javascript:beef.undo()'>just undo.</a>)</em></div>");
      beef.infoWindow.open("new", "<div class='added'><strong>Great!</strong> Please continue filling out the form to the left.<br /><br /><em>(Didn't mean to put this marker on the map? No problem, <a href='javascript:beef.undo()'>just undo.</a>)</em></div>");

      this.markerOnMap = true

      $("#comments").fadeIn();
      $("#comment_body").focus();

      if (e.commentNote) {
        $("#comment_note").val(e.commentNote);
        $("#comment_note_input").removeClass("hidden");
      }
      else
      {
        $("#comment_note_input").addClass("hidden");
      }
    } else { beef.variables.info.open(map, beef.variables.markers["new"]) }
  },

  lineToMarker: function() {
      $("#comment_lat").val(beef.variables.polylinePath.getAt(0).lat());
      $("#comment_lng").val(beef.variables.polylinePath.getAt(0).lng());
	    $("#comment_line").val(null);
	    $("#lineToMarker").html("Please continue filling out the form to the left.");
      this.setupForm({});
      $("#comments").fadeIn();
      $("#comment_body").focus();
	  google.maps.event.clearListeners(map, "click");
	  beef.variables.polylinePath.clear();
  },

  updateForm: function() {
    $("#map_lat").val(map.getCenter().lat());
    $("#map_lng").val(map.getCenter().lng());
    $("#map_zoom").val(map.getZoom());
  },

  toggleKML: function(id) {
    let layer = beef.variables.kmls[id];
    if (layer.hasOwnProperty('url')) {
      // kml layer
      if (layer.getMap() != null) {
        layer.setMap(null);
        $("#kml_" + id + " a").addClass("disabled");
      } else {
        layer.setMap(map);
        $("#kml_" + id + " a").removeClass("disabled");
      }
    }   
    else {
      // geoXml layer (dashed polylines)
      if ((layer.gpolylines.length > 0 && layer.gpolylines[0].getMap() != null) || (layer.gpolygons.length > 0 && layer.gpolygons[0].getMap() != null)) {
        geoXml.hideDocument(layer);
        $("#kml_" + id + " a").addClass("disabled");
      }
      else {
        geoXml.showDocument(layer);
        $("#kml_" + id + " a").removeClass("disabled");
      }
    }
  }
}

topo = function() {
  var topo_layer = new google.maps.ImageMapType({
    getTileUrl: function(tile, zoom) {
      return "http://services.arcgisonline.com/ArcGIS/rest/services/USA_Topo_Maps/MapServer/tile/" + zoom + "/" + tile.y + "/" + tile.x
    },
    tileSize: new google.maps.Size(256, 256),
    isPng: true,
    maxZoom: 15,
    minZoom: 5,
    name: "Topography",
    alt: "Detailed topographic maps"
  });
  map.mapTypes.set('topography', topo_layer);
}

toggleQuestionAnswer = function(e) {
  if (e.value == "Text") {
    $("#optional_answers").hide();
  }
  else {
    $("#optional_answers").show();
  }
}
